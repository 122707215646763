import Api from '@/Services/Api'

export default {
    async getListFilters() {
        let response = await Api().get('/messagerie/liste-filtres')
        return response.data
    },
    async getParamsMessagerie() {
        let response = await Api().get('/messagerie/recuperation-parametres-messagerie')
        return response.data
    },
    async getSettings() {
        let response = await Api().get('/messagerie/recuperation-configuration-messagerie')
        return response.data
    },
    async postFilter(datas) {
        let response = await Api().post('/messagerie/ajout-filtre', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async deleteFilter(datas) {
        let response = await Api().delete('/messagerie/suppression-filtre', { params: { filters: datas } })
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async addFiches(datas) {
        let response = await Api().post('/messagerie/ajout-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async addMessageFiche(datas) {
        let response = await Api().post('/messagerie/ajout-message-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async updateMessageFiche(datas) {
        let response = await Api().post('/messagerie/modification-message-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async getFiches(datas) {
        let response = await Api().post('/messagerie/recuperation-fiches', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async getFichesByTicket(datas) {
        let response = await Api().post('/messagerie/recuperation-fiche-par-ticket', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async getFichesById(datas) {
        let response = await Api().post('/messagerie/recuperation-fiche-par-id', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async addUserSeenFiche(datas) {
        let response = await Api().post('/messagerie/user-voit-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async changeParamsFiche(datas) {
        let response = await Api().post('/messagerie/modification-parametre-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async addRemoveUsersFiche(datas) {
        let response = await Api().post('/messagerie/gestion-users-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async addRemoveTagsFiche(datas) {
        let response = await Api().post('/messagerie/gestion-tags-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async saveSettings(datas) {
        let response = await Api().post('/messagerie/save-configuration-messagerie', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async filtersAdvanced(datas) {
        let response = await Api().post('/messagerie/filtres-avances', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async fixturesFiches(datas) {
        let response = await Api().post('/messagerie/fixtures', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async getMessagesFiche(datas) {
        let response = await Api().post('/messagerie/recuperation-messages-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async uploadFile(datas){
        let response = await Api().post('/messagerie/upload-file', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async removeFile(datas){
        let response = await Api().post('/messagerie/remove-file', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async downloadFile(datas){
        let response = await Api().post('/messagerie/download-file', datas, { responseType: 'blob'})
        if (response.headers["content-type"]) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async changeDetailsFiche(datas) {
        let response = await Api().post('/messagerie/modification-details-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async archiveFiche(datas) {
        let response = await Api().post('/messagerie/archive-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async doublonFiche(datas) {
        let response = await Api().post('/messagerie/doublon-fiche', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async changeModule(datas) {
        let response = await Api().post('/messagerie/modification-module', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
    async exportStats(datas) {
        let response = await Api().post('/messagerie/export-stats-csv', datas)
        if (response.headers["content-type"] && response.headers["content-type"].includes("application/json")) {
            return response.data
        } else {
            return { error: response.data }
        }
    },
        // permet d'enrengistrer dans la fiche une date previsionnelle
    async saveDatePrevisionnelle(datas) {
        let response = await Api().post('/messagerie/ajout-date-previsionnelle', datas)
        return response.data
    },
    // permet de mettre a jour dans la fiche une date previsionnelle
    async updateExceptedDate(datas) {
        let response = await Api().post('/messagerie/update-date-previsionnelle', datas)
        return response.data
    },
    // permet de supprimer dans la fiche une date previsionnelle
    async deleteDatePrevisionnelle(datas) {
        let response = await Api().post('/messagerie/delete-date-previsionnelle', datas)
        return response.data
    },
    // suivis des fiches
    async suiviFiche(datas) {
        let response = await Api().post('/messagerie/transmettre-fiche', datas)
        return response.data
    },
    async removeSuiviFiche(id) {
        let response = await Api().delete(`/messagerie/retirer-suivi-fiche/${id} `)
        return response.data
    },
    // appel client
    async saveAppelClient(datas) {
        let response = await Api().post('/messagerie/appel-client', datas)
        return response.data
    },
    // suprimmer historique d'appel client
    async deleteHistory(id) {
        let response = await Api().delete(`/messagerie/supprimer-appel-client/${id}`)
        return response.data
    },
    // archiver un msg 
    async archiveMessage(datas) {
        let response = await Api().post('/messagerie/archiver-message-fiche', datas)
        return response
    },
    async getAllFiches() {
        let response = await Api().get('/messagerie/recuperation-fiches-dashboard')
        return response
    }, 
    // ByType
    async getFichesByType(start, end) {
        let response = await Api().post('/messagerie/recuperation-fiches-dashboard-type', { start, end })
        return response
    },
    //  ByImpact
    async getFichesByImpact(start, end) {
        let response = await Api().post('/messagerie/recuperation-fiches-dashboard-impact', { start, end })
        return response
    },
    // ByModule
    async getFichesByModule(start, end) {
        let response = await Api().post('/messagerie/recuperation-fiches-dashboard-module', { start, end })
        return response
    },
    // byClient
    async getFichesByClient(start, end) {
        let response = await Api().post('/messagerie/recuperation-fiches-dashboard-client', { start, end })
        return response
    },
    // send mail
    async sendMail(datas) {
        let response = await Api().post('/messagerie/send-mail-stats', datas)
        return response
    },
}
